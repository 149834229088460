//color

$bg-dark :                      #dededd;
$tertiary:                      #f39d72;

//BS touchspin
$bs-touchspin-boxshadow:        2px 2px 4px 0 rgba(0,0,0,.2);

//option
$enable-fluid-layout:            false;

// main layout
$header-bg:                     $white;
$footer-bg:                     $white;
$wrapper-bg:                    #f1f1f1;
$footer-container-margin-top:   $spacer;
//product miniature

$pm-title-color:                $gray-700;
$pm-title-font-size:            $font-size-base;
$pm-title-font-weight:          400;

//cart
$promo-code-bg:                 darken($body-bg,10%);

//offcanvas
$zindex-offcanvas :             $zindex-modal;
$offcanvas-width :              300px;

//product slider
$product-thumb-margin:          5px;
$product-thumb-width:           100px;
$product-thumb-height:          100px;
$product-to-show:               3;//if you edit this, you have to add centerPadding ppy in data-slick with same value
$product-thumb-wrap-width:      $product-to-show * ($product-thumb-width + 2 * $product-thumb-margin) - 2 * $product-thumb-margin;
$product-thumb-wrap-width-2:    2 * ($product-thumb-width + 2 * $product-thumb-margin) - 2 * $product-thumb-margin;
$product-thumb-wrap-height:     $product-thumb-height + $product-thumb-margin * 2;

//prices
$regular-price-font-size:       $font-size-base * .875;
$regular-price-font-weight:       700;
$regular-price-color:           $gray-700;
$price-color:                   $black;
$product-price-discount-color:           $primary;
$product-regular-price-font-size:       $font-size-base * 1.25;


//product color box
$colorbox-border-color:         #232323;

//product add to cart spinner
$spinner-width-addtocart:$btn-font-size-lg;
$spinner-height-addtocart:$btn-font-size-lg;
$spinner-borderwidth-addtocart:2px;


//social share
$social-share-size:             2.5 * $font-size-base;
$social-share-bg-color:         $gray-300;

//main menu
$menu-topitem-font-size:            $font-size-base;
$menu-topitem-color:            $gray-800;
$menu-item-padding:             $spacer/2;
$menu-item-border-color:        $border-color!default;
$menu-sub-mobile-bg-color:      $wrapper-bg;

//heading
$h1-text-transform: uppercase;

//footer
$footer-margin-top:             $spacer;
$footer-padding-top:             $spacer;

//util
//$card-box-shadow : $box-shadow !default;
$card-box-shadow : null !default;
