@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');

// $color-1: #243B70;
// $color-2: #294C60;
// $color-3: #1a4d62;

// $menu-color-1: #1a4d62;
// $menu-color-2: #1e576a;
// $menu-color-3: #3c7281;

$color-1: #294C60;
$color-2: $color-1;
$color-3: $color-1;

// $discount-color: #b50b27;
$discount-color: #ea6227;

$menu-color-1: $color-1;
$menu-color-2: $color-1;
$menu-color-3: $color-1;

$light: #f5f6f7;

$primary: $color-1;
$secondary: $color-2;
$body-color: $color-1;
$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family: $font-family-sans-serif;
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1400px
);
$menu-item-border-color: #eaeaea;
$header-height: 150px;

@import "partials/variables";
@import "bootstrap";
@import "partials/mixins";
@import "partials/custom_variables";
@import "partials/fonts";
@import "partials/commons";
@import "partials/lazysizes";

@import "components/alert";
@import "components/block-social";
@import "components/cart";
@import "components/categories";
@import "components/customer";
@import "components/footer";
@import "components/imageslider";
@import "components/mainmenu";
@import "components/offcanvas";
@import "components/products";
@import "components/facet";
@import "components/slick";
@import "components/slick-theme";
@import "components/header";
@import "components/productcomments";
@import "components/utilities";
@import "partials/bs_alpha";

body {
    // padding-top: $header-height;
    // &#category { padding-top: 160px }
    overflow-x: hidden;
    background-color: #fff;
    &.footer-absolute {
        position: relative;
        min-height: 100vh;
    }
    &.scroll-bar-fix { padding-right: 17px }
    &:after { 
        position: fixed;
        content: '';
        z-index: 100;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(#000,.5);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity .15s 0s ease-in-out, visibility 0s .15s linear;
    }
    &.menu-opened {
        /*
        overflow: hidden!important;
        &.scroll-bar-fix { padding-right: 17px }
        */
        &:after {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transition: opacity .15s 0s ease-in-out, visibility 0s 0s linear;
        }
    }
    &#checkout {
        #wrapper {
            padding-top: 50px;
        }
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

img { max-width: 100% }

.fw-100 { font-weight: 100 }
.fw-200 { font-weight: 200 }
.fw-300 { font-weight: 300 }
.fw-400 { font-weight: 400 }
.fw-500 { font-weight: 500 }
.fw-600 { font-weight: 600 }
.fw-700 { font-weight: 700 }
.fw-800 { font-weight: 800 }
.fw-900 { font-weight: 900 }

.bg-cover { background-size: cover!important }

#header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    margin-bottom: 0;
    height: $header-height;
    box-shadow: 0 0 13px 0 rgba(#000,0.11)!important;
	.scroll-bar-fix & { padding-right: 17px }
	.search-widget {
		max-width: 300px;
		@include media-breakpoint-down(lg) { max-width: 180px; }
		display: inline-block;
		margin-right: 3px;
		@include media-breakpoint-up(md) { margin-right: 25px; }
		position: relative;
		top: 3px;
		.input-group {
			input {
				padding: 0 10px 0 50px!important;
				box-shadow: none!important;
				outline: none!important;
				border: 1px solid $color-1!important;
				border-radius: 2px;
				height: 40px!important;
				line-height: 40px!important;
				font-size: 12px;
				font-weight: 600;
				color: #444;
				&::-webkit-input-placeholder { color: #808080 } /* Chrome/Opera/Safari */
				&::-moz-placeholder { color: #808080 } /* Firefox 19+ */
				&:-ms-input-placeholder { color: #808080 } /* IE 10+ */
				&:-moz-placeholder { color: #808080 } /* Firefox 18- */
			}
			button {
				position: absolute;
				z-index: 10;
				top: 0;
				left: 0;
				padding-left: 10px;
				padding-right: 10px;
				color: #fff!important;
				height: 100%;
				background-color: $color-1;
				border-radius: 2px 0 0 2px;
			}
		}
	}
	.main-logo {
		margin: 0 auto;
		@include media-breakpoint-down(sm) { margin-top: 15px; }
		display: block;
		$ratio: 200 / 79.15;
		width: 170px;
		height: 170 / $ratio + px;
		@media (max-width: 400px) {
			width: 150px;
			height: 150 / $ratio + px;
		}
		// @media (max-width: 350px) {
		// 	width: 100px;
		// 	height: 100 / $ratio + px;
		// }
		img {
			width: 100%;
			height: 100%;
		}
	}
    .header-top {
        height: auto;
        padding-top: 15px;
        > .container,
        > .container > .row { height: 100% }
        #_desktop_language_selector {
            display: inline-block;
            vertical-align: middle;
			margin-bottom: 1px;
			position: relative;
			top: 3px;
            .dropdown-menu {
                min-width: 0;
                padding: 0;
                a {
                    text-transform: uppercase;
                    font-size: 13px;
                    font-weight: 500;
                }
            }
            button {
                padding-right: 0;
                padding-left: 0;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                outline: none!important;
                box-shadow: none!important;
                color: #808080!important;
                &:hover { color: $color-1!important }
            }
        }
        #_desktop_user_info {
            position: relative;
            top: 3px;
            margin-left: 0;
            display: inline-block;
            a {
                display: inline-block;
                padding: 8px 0;
                font-size: 12px;
                font-weight: 600;
                color: #808080!important;
                &:hover { color: $color-1!important }
            }
        }
        #_desktop_cart {
            margin-right: -.5rem;
            display: inline-block;
            position: relative;
            top: 2px;
            .blockcart {
                background-color: transparent!important;
                i { font-size: 18px }
                i, .cart-products-count {
                    color: $color-1!important;
                    vertical-align: middle;
                }
                .cart-products-count {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
		}
		.menu-divider {
			hr {
				margin: 0;
				margin-top: 20px;
				border-color: #eaeaea;
			}
		}
        #_desktop_top_menu {
            .menu-top {
                a {
                    padding: 13px 16px;
                    font-size: 14px;
                    font-weight: 700;
                    color: $color-1!important;
                }
                .menu-sub {
                    position: absolute;
                    overflow: none;
                    top: $header-height!important;
                    // height: calc(100% - $header-height);
                    width: 100vw;
                    // background-color: rgba(#000,.6);
                    box-shadow: none;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: transform 1s 0s ease-in-out, visibility 0s 0.5s, opacity 0.15s 0s linear;
                    &.active {
                        visibility: visible;
                        pointer-events: all;
                        opacity: 1;
                        transition: transform 1s 0s ease-in-out, visibility 0s 0s, opacity 0.15s 0s linear;
                    }
                    #desktop--menu {
                        position: relative;
                        > .desktop-menu-home-section {
                            position: absolute;
                            top: 0;
                            right: 0;
							width: 66.6666%;
							@media (max-width: 991px) { width: 60% }
                        }
                    }
                    .menu-sub__content {
                        max-width: 1000px;
                        padding: 0;
                        box-shadow: 0 0 25px rgba(#000,.06);
                        background-color: #f0f0f0;
                        a, .load-more-button {
                            cursor: pointer;
                            padding: 8px 15px;
                            @media (min-width: 1200px) {
                                padding-left: 30px;
                                padding-right: 30px;
                            }
                            text-transform: none;
                            font-weight: 500;
                            &:hover { text-decoration: underline }
                            position: relative;
                            i {
                                position: absolute;
                                top: 8px;
                                right: 23px;
                                @media (max-width: 1199px) { right: 8px }
                            }
                        }
                        .sub-menu-column {
                            .menu-sub__list { display: block }
                            position: relative;
                            padding: 30px 0;
                            // box-shadow: 0 0 10px rgba(#000,.1);
                            width: 33.3333%;
                            @media (max-width: 991px) { width: 40% }
                            height: 100%;
                            &[data-sub-menu-level="0"] {
                                background-color: #fff;
                                a {
                                    color: $menu-color-1!important;
                                    &.active {
                                        background-color: $menu-color-1;
                                        color: #fff!important;
                                    }
                                }
                            }
                            &[data-sub-menu-level="1"] {
                                background-color: $menu-color-1;
                                a {
                                    color: #fff!important;
                                    // &.active { background-color: $menu-color-2 }
                                }
                                .fontello-arrow-right { display: none; }
                                > ul {
                                    columns: 2;
                                    column-fill: auto;
                                    height: 100%;
                                    > li {
                                        width: 100%;
                                        display: inline-block;
                                    }
                                }
                            }
                            &[data-sub-menu-level="2"] {
                                background-color: $menu-color-2;
                                a {
                                    color: #fff!important;
                                    &.active { background-color: $menu-color-3 }
                                }
                                @media (min-width:768px) { display: none }
                            }
                            &[data-sub-menu-level="3"] {
                                background-color: $menu-color-3;
                                a {
                                    color: #fff!important;
                                    &.active { color: $menu-color-1!important }
                                }
                            }
                            &[data-sub-menu-level="1"], &[data-sub-menu-level="2"], &[data-sub-menu-level="3"] {
                                position: absolute;
                                left: 100%;
                                top: 0;
                                width: calc(200% + 2px);
                                visibility: hidden;
                                pointer-events: none;
                                @media (max-width: 991px) { width: 150% }
                            }
                        }
                        .menu__item {
                            padding: 0;
                            &.active {
                                > .sub-menu-column  {
                                    visibility: visible!important;
                                    pointer-events: all!important;
                                }
                            }
                        }
					}
                }
            }
            .load-more-button {
                color: blue;
                font-weight: 700;
            }
            .load-more-hidden { display: none; }
		}
    }
}

#homefeatured {
	@include media-breakpoint-down(md) { display: none!important }
	.h5 {
		padding: 37px 30px 10px 30px;
	}
	a {
		display: flex;
		padding-left: 30px!important;
		padding-right: 30px!important;
		align-items: center;
		img {
			width: 45px;
			height: 45px;
			margin-right: 10px;
			border-radius: 50%;
			background-color: #fff;
			padding: 5px;
		}
	}
}

.autocomplete-suggestions {
	@include media-breakpoint-up(sm) {
		width: 300px!important;
	}
	border: 0!important;
	box-shadow: 0 2px 15px rgba(#000,.1)!important;
	margin-top: 10px;
	.autocomplete-suggestion {
		padding: 10px;
		img {
			margin-right: 10px!important;
			border-radius: 50%;
			padding: 5px;
			background-color: #fff;
		}
	}
}

#mobile_top_menu_wrapper {
    .fontello-arrow-right { display: none; }
    .menu__item-header { padding: 0 }
    #top-menu a { padding: 13px 15px }
    .load-more-button { display: none; }
}

.custom-expand-button {
    &, * { @extend .noselect }
    > span { padding: 11px 10px; }
    cursor: pointer;
    .navbar-toggler-inside {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid #ddd;
        text-align: center;
        i { vertical-align: middle; }
    }
}

.home-slider #carousel {
    height: 500px;
    .slick-list,
    .slick-track,
    .slick-slide > div,
    .slick-slide > div > .slide-item { height: 100% }
    .slide-item {
        position: relative;
		background-size: cover!important;
		height: 100%;
        &.dark-cover:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#000,.3);
        }
        .slide-content {
            position: relative;
            z-index: 100;
            padding: 30px;
            margin: 0 auto;
            text-align: center;
            .slide-title {
                font-size: 30px;
                color: #fff;
                text-shadow: 4px 4px 5px rgba(#000,.3);
                font-weight: 700;
				max-width: 500px;
				margin-left: auto;
				margin-right: auto;
			}
			.slide-subtitle {
				font-size: 18px;
				@include media-breakpoint-down(sm) {
					font-size: 16px;
				}
				color: #fff;
				text-shadow: 4px 4px 5px rgba(#000,.3);
				font-weight: 600;
				max-width: 700px;
				margin-top: 10px;
			}
            a {
                p {
                    margin: 0;
                    font-size: inherit;
                    font-weight: inherit;
                    color: inherit;
                }
            }
        }
	}
	.slick-dots {
		margin-top: -50px;
		z-index: 100;
		text-align: center;
		position: relative;
		font-size: 0;
		li {
			display: inline-block;
			border-radius: 50%;
			border: 0;
			cursor: pointer;
			padding: 8px;
			button {
				background: #fff!important;
				border: 0!important;
				outline: none!important;
				width: 9px;
				height: 9px;
				border-radius: 50%;
				padding: 0;
				margin: 0;
			}
			opacity: 0.5;
			&.slick-active, &:hover {
				opacity: 1;
			}
		}
	}
	.slick-arrow > i {
		background: transparent;
		color: #fff;
		font-size: 2rem;
	}
}

.button-1 {
    display: inline-block;
    padding: 10px 40px;
    color: $color-1;
    background-color: transparent;
    font-weight: 600;
    font-size: 15px;
    border: 1px solid $color-1;
    cursor: pointer;
    &:hover {
        background-color: $color-1;
        color: #fff;
    }
}

.button-1-white {
    @extend .button-1;
    background-color: #fff;
    border-color: #fff;
    &:hover {
        background-color: $color-1;
        border-color: $color-1;
        color: #fff;
    }
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.row-5 {
    margin-left: -5px;
    margin-right: -5px;
    > [class^='col-'], > [class*=' col-'] {
        padding-left: 5px;
        padding-right: 5px;
    }
}

#footer {
	.footer-logo {
		$ratio: 200 / 79.15;
		width: 170px;
		height: 170 / $ratio + px;
		@media (max-width: 400px) {
			width: 150px;
			height: 150 / $ratio + px;
		}
		@media (max-width: 350px) {
			width: 100px;
			height: 100 / $ratio + px;
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
    .footer-before-container {
        background-color: #dbdcdd;
        .block_newsletter {
            input[type="email"] {
                max-width: 300px;
                background-color: transparent!important;
                border: 0;
                border-bottom: 1px solid $color-1;
                box-shadow: none!important;
                outline: none!important;
            }
            form {
                max-width: 350px;
                margin: 0 auto;
                button {
                    @media (min-width: 768px) {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
                .small { font-size: 11px }
            }
        }
    }
    .footer-container {
        background-color: $color-3;
        margin-top: 0;
        color: #aaa;
        .footer__title { color: #fff }
        a {
            color: #aaa!important;
            &:hover { text-decoration: underline }
        }
        .footer-social {
            a {
                color: #fff!important;
                font-size: 20px;
            }
        }
    }
}

.breadcrumb-wrapper {
    background-color: #e2e3e3;
    text-align: center;
    font-size: 12px;
    nav {
        display: inline-block;
        .breadcrumb {
            background: transparent;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

#category {
    background-color: #f5f6f7;
    .category-custom-header {
		background-color: #fff;
		&.category-custom-header-with-image {
			@extend .bg-cover;
			* { color: #fff!important }
			padding-top: 150px!important;
			padding-bottom: 150px!important;
			@media (max-width:767px) {
				padding-top: 80px!important;
				padding-bottom: 80px!important;
			}
			position: relative;
			&:before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#000,.3);
			}
			> .container {
				position: relative;
				z-index: 10;
			}
		}
	}
    #left-column .block-categories { padding-left: 0; }
}

// ==================================== column 1/5

.col-sm-1-5 {
    @media (min-width: 576px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}
.col-md-1-5 {
    @media (min-width: 768px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}
.col-lg-1-5 {
    @media (min-width: 992px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}
.col-xl-1-5 {
    @media (min-width: 1200px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.col-sm-2-5 {
    @media (min-width: 576px) {
        flex: 0 0 40%;
        max-width: 40%;
    }
}
.col-md-2-5 {
    @media (min-width: 768px) {
        flex: 0 0 40%;
        max-width: 40%;
    }
}
.col-lg-2-5 {
    @media (min-width: 992px) {
        flex: 0 0 40%;
        max-width: 40%;
    }
}
.col-xl-2-5 {
    @media (min-width: 1200px) {
        flex: 0 0 40%;
        max-width: 40%;
    }
}

// ====================================

.product-miniature {
    > .card {
		.card-body {
			padding-bottom: 40px;
			position: relative;
			overflow: hidden;
			.highlighted-informations {
				left: 0;
				bottom: 0;
				top: auto;
				padding: 0;
				transform: translateY(100%);
				transition: transform 0.2s 0s ease-in-out, box-shadow 0.2s 0s ease-in-out;
				&:hover {
					background-color: $color-1;
					color: #fff;
				}
				.variant-links { display:none }
			}
		}
        border: 0!important;
        transition: box-shadow 0.2s 0s ease-in-out;
        &:hover {
            box-shadow: 0 0 15px rgba(#000,.1);
            .highlighted-informations {
                box-shadow: 0 0 15px rgba(#000,.1);
                transform: translateY(0);
            }
        }
        .product-description {
            justify-content: normal;
            .product-price-and-shipping {
                .price {
                    font-size: 20px;
                    margin-top: 1rem;
                }
                .regular-price {
                    color: #999;
                    padding-right: 5px;
                    border-right: 2px solid #bbb;
                    font-size: 15px;
                }
                .discount-percentage, .discount-amount {
                    font-size: 15px;
                    font-weight: 600;
                    color: $discount-color;
                }
            }
        }
        .product-flags {
			left: 6px;
			.discount-product {
				margin-top: 6px;
				padding: 4px 9px;
				border-radius: 2px;
				font-size: 12px;
				font-weight: 400;
				letter-spacing: .5px;
				box-shadow: 0 0 10px rgba(#fff,.5);
				background-color: $discount-color;
			}
		}
    }
}

.quickview.modal, #product {
    .btn.add-to-cart {
		width: 100%;
        max-width: 300px;
        font-size: 15px;
        i {
            font-size: 19px;
            margin-right: 8px;
        }
    }
}

.quickview.modal {
	.btn-zoom { display: none!important; }
	.product-prices {
		.product-price {
			.current-price-display {
				font-size: 25px;
			}
		}
		.regular-price {
			font-size: 16px;
			color: #999;
		}
	}
	.product-actions {
		button.add-to-cart {
			margin-bottom: 1rem;
		}
		.buttons_bottom_block {
			margin-top: 20px!important;
			.user-not-logged & { display: none!important }
		}
	}
	.social-sharing {
		.social-share-btn--product {
			margin-left: 0;
			margin-right: 15px;
			text-align: center;
			line-height: 2.25rem;
		}
	}
	.modal-footer {
		justify-content: space-between;
	}
}

.block-categories {
    background-color: transparent!important;
    border: 0!important;
    margin-bottom: 0!important;
    padding-bottom: 0;
    .category-sub-menu .category-sub-menu { padding-left: 10px }
    .category-sub__item {
        position: relative;
    }
    .category-sub__item--0 {
        padding: 0;
        border-bottom: 0;
    }
    a {
        padding: 7px 30px 7px 0;
        display: block;
		border-bottom: 1px solid #ccc;
		&:hover {
			border-bottom-color: $color-1;
		}
		float: none!important;
		&.active {
			font-weight: 700;
		}
    }
    .expand-toggler, .icon-collapse {
        border-radius: 50%;
        border: 1px solid rgba($color-1,.3);
        cursor: pointer;
        position: absolute;
        top: 6px;
		right: 0;
		transition: all .28s 0s linear;
	}
	.expand-toggler {
		transform: rotate(180deg);
		background-color: $color-1;
		color: #fff;
		&.collapsed {
			background-color: transparent;
			color: $color-1;
			transform: rotate(0);
		}
	}
	//.current_active_category_parent > i:not(.collapsed) {
	i.icon-collapse:not(.collapsed) {
		transform: rotate(180deg);
		background-color: $color-1;
		color: #fff;
	}
}

.custom-select {
    background-color: transparent!important;
    box-shadow: none!important;
    border-color: $color-1!important;
}

.pagination-wrapper {
    padding: 1.5rem 0;
    position: relative;
    margin-top: 2rem;
    &:before, &:after {
        position: absolute;
        content: '';
        left: 15px;
        top: 0;
        width: calc(100% - 30px);
        height: 1px;
        background-color: $color-1;
    }
    &:after {
        top: auto;
        bottom: 0;
    }
}

#search_filters {
	padding-left: 0;
    background-color: transparent!important;
    border: 0!important;
    .ui-widget-header {
        background-color: #dadbdc!important;
	}
	.collpase--facet.collapse:not(.show) { display: none }
}

#js-active-search-filters {
    background-color: #ecedee!important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance:textfield;
}

.bootstrap-touchspin { box-shadow: none!important; }

#product {
    .custom-product-row {
        @include media-breakpoint-up(lg) {
            margin-left: -40px;
            margin-right: -40px;
            > div {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
	}
	.product-flags {
		left: 15px!important;
		@include media-breakpoint-up(lg) { left: 30px!important }
		.product-flag {
			margin-top: 6px;
			padding: 4px 9px;
			border-radius: 2px;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: .5px;
			box-shadow: 0 0 10px rgba(#fff,.5);
			&.discount-product {
				background-color: $discount-color;
			}
		}
	}
	.images-container {
		.product-thumbs {
			width: 100%!important;
			max-width: 320px;
		}
	}
    .product-comments-additional-info {
        .additional-links {
            .link-comment {
                padding-left: 0;
                &:hover { text-decoration: underline }
            }
        }
    }
    .product-prices {
        .current-price-display { font-size: 30px }
        .currency {
            font-size: 20px;
            font-weight: 600;
		}
		.product-discount {
			.regular-price {
				font-size: 17px;
				color: #999;
			}
		}
		.discount-amount, .discount-percentage {
			color: $discount-color;
			font-size: 17px;
		}
    }
    .product-information {
        .product-quantity {
            margin-top: 0;
        }
        .btn.add-to-cart {
            margin-top: 1rem;
        }
        #product-availability {
            margin-top: 1rem;
            display: block;
		}
		.buttons_bottom_block {
			margin-top: 30px!important;
		}
        .img-metodi-pagamento {
			width: 100%;
			max-width: 300px;
            margin-top: 30px;
            // filter: grayscale(100%);
            // opacity: 0.5;
            // transition: all 0.15s 0s ease-in-out;
            // &:hover {
            //     filter: grayscale(0);
            //     opacity: 1;
            // }
        }
    }
    .social-sharing {
        a {
            margin-left: 0!important;
            width: 36px;
            height: 36px;
            background-color: #aaa;
            box-shadow: none!important;
            color: #fff!important;
            text-align: center;
            line-height: 36px;
            &:hover {
                color: $color-1!important;
            }
        }
    }
    .specifiche-tecniche {
        table {
            td { padding-left: 0 }
        }
    }
    .prodotti-correlati {
        .slider-prodotti-correlati {
            .slick-list {
                padding-top: 10px;
                padding-bottom: 10px;
                .slick-track {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .product-miniature {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 0!important;
            }
        }
	}
	.block-categories {
		padding-top: 0;
		padding-left: 0;
		@extend .pb-6;
	}
}

.page-wrapper--module-ph_simpleblog-single {
    border: 0;
    .page-header {
        border: 0;
        background-color: transparent;
        padding: 0;
    }
    .page-content {
        padding: 0;
        .simpleblog__post {
            box-shadow: none;
            .simpleblog__post__content {
                padding: 0;
            }
        }
        .simpleblog__share {
            ul > li > .btn {
                width: 36px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                padding: 0!important;
                border-radius: 50%;
                i {
                    margin: 0!important;
                    top: 0!important;
                }
            }
        }
    }
}

.featured-products {
    .products-slick {
        .slick-list {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

#homecategoriez {
    .homecategoriez-block-in {
        background-color: #f0f0f0;
		padding: 15px;
		@include media-breakpoint-up(xl) { padding: 30px }
        a {
            display: block;
			padding: 10px 30px 10px 15px;
			position: relative;
            &:hover {
                background-color: $color-1;
                color: #fff;
			}
			span {
				position: absolute;
				top: 50%;
				right: 15px;
				transform: translateY(-50%);
			}
        }
    }
}

#module-blockgiftlistpro-mywishlist {
    #mywishlist_pro {
        form#creator_choose_name {
            max-width: none;
            width: 100%;
        }
    }
}

#view_wishlist {
	.product_list_aw {
		&.list {
			@include media-breakpoint-down(sm) {
				.blk_left {
					width: 100%!important;
					text-align: center!important;
					img {
						margin-left: auto!important;
						margin-right: auto!important;
					}
				}
				.wishlist_product_detail_dd {
					text-align: center!important;
					float: none!important;
					margin-left: auto!important;
					margin-right: auto!important;
					max-width: 400px;
				}
			}
		}
		.item {
			.container_aw {
				padding-top: 15px!important;
				padding-bottom: 15px!important;
			}
			.row { display: block!important; }
			[class*="col-"] { float: left; }
		}
	}
	.form_view_qty_left {
		font-size: 100%!important;
	}
    .wishlist_product_detail_dd {
        .price {
            max-width: 450px;
            width: 75%;
            margin: 0 auto;
        }
        button.add-to-cart {
            float: none;
            max-width: 450px;
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

#module-blockgiftlistpro-mywishlist {
	.product_list_aw {
		.container_aw {
			padding: 15px;
			text-align: left;
			.pdtName { text-align: left!important }
			.wishlist_product_detail_dd {
				float: none!important;
				text-align: left!important;
				ul li { text-align: left }
			}
			.tableaw {
				width: 100%;
				max-width: 400px;
			}
		}
	}
}

#module-ph_simpleblog-list {
	#main {
		border: 0!important;
	}
	#content {
		padding: 0!important;
	}
	.simpleblog__listing__post__wrapper {
		box-shadow: none!important;
	}
	.simpleblog__listing__post__wrapper__content {
		padding-left: 0!important;
		padding-right: 0!important;
	}
}

#module-ph_simpleblog-category {
	#main {
		border: 0!important;
		#content {
			padding: 0;
			.simpleblog__listing {
				margin-top: 15px;
				padding-bottom: 0;
			}
		}
	}
	.simpleblog__listing__post {
		&.post-number-1 {
			max-width: 100%!important;
			flex: 0 0 100%!important;
		}
	}
	.simpleblog__listing__post__wrapper {
		box-shadow: none!important;
		border: 1px solid #fff;
		.simpleblog__listing__post__wrapper__content {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.simpleblog__listing__post__wrapper__footer {
		padding: 0;
		background-color: transparent;
	}
}

#module-ph_simpleblog-single {
	header.page-header, #content.page-content {
		width: 100%;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
	}
	.simpleblog__post {
		.simpleblog__post-featured {
			img {
				margin: 0 0 40px 0;
			}
		}
	}
}

.blockcart-modal {
	#blockcart-modal-label {
		i {
			vertical-align: middle;
			margin-right: 10px;
		}
	}
}

#results_searchlist {
	margin-right: 0;
	.table {
		thead {
			th { border: 0 }
		}
		td { vertical-align: middle; }
	}
}

#fade {
	z-index: 1000;
}

.page-main-title h1 {
	@extend .h1;
	font-weight: 600;
}

.page-content--cms {
	padding: 0;
}

.page-cms-1, .page-cms-5 {
	@extend .py-6;
	h2 {
		@extend .h1;
		font-weight: 600;
		margin-bottom: 2rem;
		text-transform: none;
	}
}

#mypresta_mib {
	box-shadow: none!important;
	padding: 0;
	margin-bottom: 80px!important;
	// ul {
	// 	margin-left: -10px;
	// 	margin-right: -10px;
	// 	display: flex;
	// 	flex-wrap: wrap;
	// 	li {
	// 		flex: 0 0 10%;
	// 		max-width: 10%;
	// 		margin-bottom: 10px;
	// 		padding-left: 10px;
	// 		padding-right: 10px;
	// 	}
	// }
}

.home-prodotti-popolari-parte-statica {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	article {
		flex: 0 0 20%!important;
		max-width: 20%!important;
		@media (max-width:991px) {
			flex: 0 0 33.3333%!important;
			max-width: 33.3333%!important;
		}
		@media (max-width:767px) {
			flex: 0 0 50%!important;
			max-width: 50%!important;
		}
	}
}

#manufacturer {
	#content-wrapper {
		ul {
			display: flex;
			flex-wrap: wrap;
			margin-left: -30px;
			margin-right: -30px;
			li.brand {
				margin: 30px;
				.brand-infos, .brand-products { display: none; }
			}
		}
	}
}

.home-block-1,
.home-block-2,
.home-block-3 {
	img {
		height: auto!important;
	}
}